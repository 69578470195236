
export function request(method, uri, data, responseType = null) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, uri);
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300)
                resolve(xhr.response);
            else
                reject(xhr.response);
        };
        xhr.onerror = () => reject(xhr.response);
        if (responseType) xhr.responseType = responseType;
        xhr.send(data);
    });
}
